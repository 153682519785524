<script setup>
const emit = defineEmits(['onClick'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
  class: {
    type: String
  },
  style: {
    type: Object
  }
})

const onClick = () => {
  emit('onClick')
}
</script>

<template>
  <button
    class="u-button"
    :class="[props.class, {'active': active}]"
    :disabled="disabled"
    @click="onClick"
    :style="style"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
.u-button {
  border-radius: 12px;
  @include set-text($weight: 500);
  height: 48px;
  padding: 13px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $primary5;
  &:hover,
  &:active,
  &.active {
    background-color: $primary6;
  }
  &:disabled {
    background-color: $t2;
  }
  &.full {
    width: 100% !important;
  }
  &.icon {
    width: 48px !important;
    height: 48px;
  }
  &.small {
    padding: 12px 16px;
    @include set-text(13px, 500, 16px);
    height: 40px;
  }
  &.medium {
  padding: 8px 12px;
  font-size: 13px;
  font-weight: 500;
  font-family: "text-caption1-b", sans-serif;
  text-align: center;
  color: var(--gray-white, #FFF);
  height: 32px;
  width: 65px;
  border-radius: 10px;
}

  &.light-primary {
    color: $primary5;
    background-color: $primary2;
    &:hover,
    &:active,
    &.active {
      color: $primary6;
      background-color: $primary3;
    }
    &:disabled {
      color: $gray3;
      background-color: $t2;
    }
    &.dark {
      color: $primary6;
      background-color: $primary3;
      &:disabled {
        color: $gray3;
        background-color: $t2;
      }
    }
  }
  &.fade-primary{
    color: $primary5;
    background-color: $primary2;
  }
  &.gray {
    color: $gray6;
    background-color: $gray2;
    &:hover,
    &:active,
    &.active {
      color: $white;
      background-color: $gray6;
      :deep(.icon) {
        color: $white;
      }
    }
    &:disabled {
      color: $gray3;
      background-color: $t2;
    }
  }
  &.outline {
    border: 1px solid $primary3;
    color: $primary5;
    background-color: $white;
    &.small {
      border-color: $primary2;
      &:hover,
      &:active,
      &.active {
        border-color: $primary3;
        background-color: $white;
      }
      &:disabled {
        color: $gray3;
        border-color: $t2;
        background-color: $white;
      }
    }
    &:hover,
    &:active,
    &.active {
      border-color: $primary4;
      background-color: $white;
    }
    &:disabled {
      color: $gray3;
      border-color: $t2;
      background-color: $white;
    }
    &.red {
      border: 1px solid $system-red_t1;
      color: $system-red;
    }
  }
}
</style>